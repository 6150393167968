import atomize from "@quarkly/atomize";
import React, { useState, useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha"; // Import ReCaptcha

import { PickerOverlay, pickerOptions } from 'filestack-react';

const MessageForm = () => {
	const [currentPage, setCurrentPage] = useState(window.location.pathname);
	useEffect(() => {
		const handleLocationChange = () => {
			setCurrentPage(window.location.pathname);
		};

		window.addEventListener('popstate', handleLocationChange);
		return () => {
			window.removeEventListener('popstate', handleLocationChange);
		};
	}, []);
	const pageButtonMap = {
		'/quote': 'Get a personalized quote'
	};
	const buttonText = pageButtonMap[currentPage] || 'Send message';
	const [recaptchaValue, setRecaptchaValue] = useState(null); // State to store ReCaptcha validation status

	const [warningMessage, setWarningMessage] = useState(''); // State to store warning message

	const [isPickerOverlayVisible, setIsPickerOverlayVisible] = useState(false);
	const [uploadSuccess, setUploadSuccess] = useState(null);
	const [uploadedFiles, setUploadedFiles] = useState([]); // State to store uploaded files
	// Function to handle ReCaptcha validation

	const handleRecaptchaChange = value => {
		setRecaptchaValue(value);
	};

	const reCaptchaStyle = {
		'margin': '20px 0px 10px 0px'
	}; // Define the style for the button

	const attachButtonStyle = {
		'width': '200px',
		'display': 'block',
		'padding': '8px 24px 8px 24px',
		'background': '#fff',
		'borderWidth': '2px',
		'borderStyle': 'solid',
		'borderRadius': '10px',
		'borderColor': '#3481fe',
		'font': 'var(--buttonsDesktop)',
		'margin': '0 0 0 0',
		'color': '#3481fe',
		'cursor': 'pointer',
		'textAlign': 'center'
	};
	const attachButtonDisabledStyle = {
		'width': '200px',
		'display': 'block',
		'padding': '8px 24px 8px 24px',
		'background': '#fff',
		'borderWidth': '2px',
		'borderStyle': 'solid',
		'borderRadius': '10px',
		'borderColor': '#E4E8EC',
		'font': 'var(--buttonsDesktop)',
		'margin': '0 0 0 0',
		'color': 'lightgrey',
		'pointer-events': 'none',
		'textAlign': 'center'
	};
	const sendButtonStyle = {
		'display': 'block',
		'padding': '12px 24px 12px 24px',
		'background': '#26ce73',
		'borderWidth': '2px',
		'borderStyle': 'solid',
		'borderRadius': '10px',
		'borderColor': '#26ce73',
		'font': 'var(--buttonsDesktop)',
		'margin': '20px 0 0 0',
		'color': '#fff',
		'cursor': 'pointer',
		'textAlign': 'center'
	};
	const sendButtonDisabledStyle = {
		'display': 'block',
		'padding': '12px 24px 12px 24px',
		'background': 'lightgrey',
		'borderWidth': '2px',
		'borderStyle': 'solid',
		'borderRadius': '10px',
		'borderColor': 'lightgrey',
		'font': 'var(--buttonsDesktop)',
		'margin': '20px 0 0 0',
		'color': 'white',
		'pointer-events': 'none',
		'textAlign': 'center'
	};
	const warningStyle = {
		'color': 'grey',
		'margin': '5px 0px 0px 0px',
		'font-size': '0.7em',
		'text-decoration': 'underline',
		'text-underline-offset': '2px',
		'font-family': 'Inter, sans-serif'
	};
	const uploadSuccessStyle = {
		'color': '#3481fe',
		'margin': '5px 0px 0px 0px',
		'font-size': '0.9em'
	};
	const uploadFailStyle = {
		'color': 'red',
		'margin': '5px 0px 0px 0px',
		'font-size': '0.9em'
	};
	const uploadedFileStyle = {
		'width': '100%',
		'margin': '5px 0px 0px 0px',
		'font-size': '0.8em',
		'border': 'none',
		'background': 'transparent',
		'outline': '0',
		'resize': 'none',
		'font-family': 'Inter, sans-serif'
	};
	const filePickerOptions = {
		accept: ['.xlsx', '.xls', '.csv'],
		maxFiles: 5,
		maxSize: 10 * 1024 * 1024
	};
	return <div>
		      
		<div style={recaptchaValue ? attachButtonStyle : attachButtonDisabledStyle} onClick={() => setIsPickerOverlayVisible(!isPickerOverlayVisible)} disabled={uploadedFiles.length >= 5 || !recaptchaValue} // Disable the button based on conditions
		>
			        Attach files
      
		</div>
		      
		{isPickerOverlayVisible && <PickerOverlay onUploadDone={res => {
			console.log(res); // Log the success response
			// Concatenate the newly uploaded files with the existing files and update the state

			setUploadedFiles(prevFiles => [...prevFiles, ...res.filesUploaded]);
			setUploadSuccess(true); // Set upload success state
		}} onError={res => {
			console.error(res); // Log the error response

			setUploadSuccess(false); // Set upload failure state
		}} pickerOptions={filePickerOptions} apikey='AxsPxqv9URsecvmaWKgL4z' />}
		      
		{uploadSuccess && <p style={uploadSuccessStyle}>
			Upload was successful!
		</p>}
		      
		{uploadedFiles.length > 0 && <div>
			          
			<p style={uploadedFileStyle}>
				Files uploaded:
			</p>
			          
			<textarea
				name='attachments'
				id='attachments'
				style={uploadedFileStyle}
				value={uploadedFiles.map(file => `File: ${file.url} - ${file.filename} - ${(file.size / 1024 / 1000).toFixed(2)}MB`).join('\n')}
				readOnly
			></textarea>
			        
		</div>}
		      
		{!uploadSuccess && uploadSuccess !== null && <p style={uploadFailStyle}>
			Upload failed. Please try again.
		</p>}
		      
		<button style={recaptchaValue ? sendButtonStyle : sendButtonDisabledStyle} type='submit' value='submit' disabled={uploadedFiles.length >= 5 || !recaptchaValue} // Disable the button based on conditions
		>
			        
			{buttonText}
			      
		</button>
		      
		<ReCAPTCHA style={reCaptchaStyle} sitekey="6Le9owIpAAAAAF60K3Qz3xpX2gms-9_EU0oA1fd7" // Replace with your ReCaptcha site key
		onChange={handleRecaptchaChange} hl={"en"} />
		      
		<p style={warningStyle}>
			Please complete the ReCaptcha before uploading files or sending a message.
		</p>
		    
	</div>;
};

export default atomize(MessageForm)({
	name: "MessageForm",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		en: "MessageForm component"
	},
	propInfo: {
		yourCustomProps: {
			control: "input"
		}
	}
});