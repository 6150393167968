export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"653fb82dfea63c0021a836fd",
				"6543ad7fbf8f48001f73f288",
				"654bbb350072400020e56709",
				"653fb82dfea63c0021a836f9"
			]
		},
		"653fb82dfea63c0021a836f9": {
			"id": "653fb82dfea63c0021a836f9",
			"name": "404",
			"pageUrl": "404",
			"customCode": {},
			"seo": {
				"title": "404 - Route not found",
				"og:title": "404 - Route not found"
			}
		},
		"653fb82dfea63c0021a836fd": {
			"id": "653fb82dfea63c0021a836fd",
			"name": "index",
			"pageUrl": "index",
			"customCode": {}
		},
		"6543ad7fbf8f48001f73f288": {
			"pageUrl": "quote",
			"id": "6543ad7fbf8f48001f73f288",
			"name": "quote",
			"customCode": {},
			"seo": {
				"description": "Get a quote for Microsoft Excel® datasets harmonization, sanitization, standardization and conforming for e-commerce, ERPs, CMSs.",
				"og:description": "Get a quote for Microsoft Excel® datasets harmonization, sanitization, standardization and conforming for e-commerce, ERPs, CMSs.",
				"title": "Get a quote",
				"og:title": "Get a quote"
			}
		},
		"654bbb350072400020e56709": {
			"pageUrl": "talk",
			"id": "654bbb350072400020e56709",
			"name": "talk",
			"customCode": {},
			"seo": {
				"description": "Schedule a talk with an expert for Microsoft Excel® datasets harmonization, sanitization, standardization and conforming for e-commerce, ERPs, CMSs.",
				"og:description": "Schedule a talk with an expert for Microsoft Excel® datasets harmonization, sanitization, standardization and conforming for e-commerce, ERPs, CMSs.",
				"title": "Talk with a data expert",
				"og:title": "Talk with a data expert"
			}
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {},
		"seo": {
			"favicon_apple_152px": "https://uploads.quarkly.io/653fb82dfea63c0021a836e5/images/apple-touch-icon.png?v=2023-11-09T17:47:54.557Z",
			"favicon_32px": "https://uploads.quarkly.io/653fb82dfea63c0021a836e5/images/favicon-32x32.png?v=2023-11-09T17:47:40.221Z",
			"favicon_win10_270px": "https://uploads.quarkly.io/653fb82dfea63c0021a836e5/images/mstile-150x150.png?v=2023-11-09T17:48:08.451Z",
			"background_win10": "#000000",
			"og:title": "dataharmony.io | Data harmonization experts",
			"og:description": "Microsoft Excel® datasets harmonization, sanitization, standardization and conforming for e-commerce, ERPs, CMSs.",
			"og:image": "https://uploads.quarkly.io/653fb82dfea63c0021a836e5/images/android-chrome-512x512.png?v=2023-11-09T17:50:58.718Z",
			"title": "dataharmony.io | Data harmonization experts",
			"description": "Microsoft Excel® datasets harmonization, sanitization, standardization and conforming for e-commerce, ERPs, CMSs.",
			"robotsFileType": "autogenerated",
			"customSitemapDomain": "https://www.dataharmony.io"
		}
	}
}